import request from '@/utils/request'

export function getPageSearch (page, noitems) {
  return request({
    url: '/group-message/search?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function getPageFilter (page, noitems) {
  return request({
    url: '/group-message/filter?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function getPageList (page, noitems) {
  return request({
    url: '/group-message/list?page=' + page + '&item=' + noitems,
    method: 'post'
  })
}
export function searchUser (data) {
  return request({
    url: '/group-message/search',
    method: 'post',
    data
  })
}
export function filterUser (data) {
  return request({
    url: '/group-message/filter',
    method: 'post',
    data
  })
}
export function listGroup (data) {
  return request({
    url: '/group-message/list',
    method: 'post',
    data
  })
}
export function store (data) {
  return request({
    url: '/group-message',
    method: 'post',
    data
  })
}
export function destroy (_id) {
  return request({
    url: '/group-message/' + _id,
    method: 'delete'
  })
}
export function send (_id) {
  return request({
    url: '/group-message/send/' + _id,
    method: 'get'
  })
}
export function exportExcel (data) {
  return request({
    url: '/group-message/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
