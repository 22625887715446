<template>
  <div class="app-container">
    <el-row class="margin-bottom-20" >
      <el-col :lg="11" :md="11" :sm="14" :xs="14" :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="Buscar" v-model="searchForm.query" v-on:change="handleSearch()" clearable></el-input>
      </el-col>
        <el-button
          class="margin-left-20"
          @click.stop.prevent="handleSearch"
          type="primary"
          icon="el-icon-search"
          size="mini"
        >Buscar</el-button>
        <el-button
          class="margin-left-20"
          @click.stop.prevent="handleClean"
          type="warning"
          icon="el-icon-delete"
          size="mini"
        >Limpiar Filtros</el-button>
        <el-button
          class="margin-left-20"
          @click.stop.prevent="exportExcelFile"
          type="success"
          icon="el-icon-bottom"
          size="mini"
        >Exportar Excel</el-button>
        <el-button
          class="margin-left-20"
          @click.stop.prevent="handleCreated"
          type="success"
          icon="el-icon-circle-plus-outline"
          size="mini"
        >Nuevo grupo</el-button>
    </el-row>
     <el-row :gutter="20">
      <el-col :lg="5" :sm="8" :offset="0">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Fecha inicial</span> <br>
          <el-date-picker
            @change="handleSearch()"
            clearable
            size="mini"
            v-model="searchForm.initDate"
            type="date"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Mayor a ">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :lg="5" :sm="24" :offset="0">
        <div class="grid-content bg-purple">
          <span class=" margin-filter">Fecha final</span> <br>
          <el-date-picker
            @change="handleSearch()"
            clearable
            size="mini"
            v-model="searchForm.endDate"
            type="date"
            format="dd-MM-yyyy"
            value-format="yyyy-MM-dd"
            placeholder="Menor a">
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end">
        <el-pagination small @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
    <el-table
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
      style="width: 100%"
      size="mini"
      :row-class-name="tableRowClassName"
    >
      <!-- <el-table-column label="Correo" sortable  prop="email" fixed width="220">
      </el-table-column> -->
      <el-table-column label="ID grupo" align="center">
        <template slot-scope="scope">
           <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">{{scope.row._id}}</div>
            <span>{{ scope.row._id.substr(-4) }}</span>
          </el-tooltip>
          <!-- <span>{{ scope.row._id }}</span> -->
        </template>
      </el-table-column>
      <el-table-column label="Titulo" align="center" width="150">
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>

      </el-table-column>
       <!-- <el-table-column label="Descripción" align="center" width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.description }}</span>
        </template>

      </el-table-column> -->
      <el-table-column label="No. Usuarios" align="center" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.usersCount }}</span>
        </template>

      </el-table-column>
      <el-table-column label="Cumpleaños" align="center" width="100">
         <template slot-scope="scope" >
          <span>{{ scope.row.isBirthDate?'Si':'No' }}</span>
        </template>

      </el-table-column>
      <el-table-column label="Estatus" align="center" width="100">
        <template slot-scope="scope" >
          <span>{{ scope.row.status=='Uninitialized'?'Sin enviar':''}}</span>
          <span>{{ scope.row.status=='Waiting'?'En espera':''}}</span>
          <span>{{ scope.row.status=='Sended'?'Enviado':''}}</span>
          <span>{{ scope.row.status=='Error'?'Error':''}}</span>
        </template>
      </el-table-column>
      <el-table-column label="Envio" align="center" width="150">
        <template slot-scope="scope" >
          <span>{{ scope.row.schedulingDateTime | formatDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Creado" align="center" width="150">
        <template slot-scope="scope" >
          <span>{{ scope.row.created_at | formatDateTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Operación" width="250" align="center">
          <template slot-scope="scope" >
            <el-button
              @click.stop.prevent="handleSend(scope.$index, scope.row)"
              size="mini"
              type="primary"
              round
              :loading="loadingSend"
              :disabled="scope.row.status == 'Sended' || scope.row.status == 'Waiting'?true:false"
            >Enviar <i class="el-icon-arrow-right el-icon-position"></i></el-button>
            <el-button
              @click.stop.prevent="handleDestroy(scope.$index, scope.row)"
              size="mini"
              type="danger"
              round
              :loading="loadingDelete"
              :disabled="scope.row.status == 'Sended' || scope.row.status == 'Waiting'?true:false"
            >Eliminar <i class="el-icon-arrow-right el-icon-remove-outline"></i></el-button>
            <!-- <span>{{ scope.row.deviceLoggersLast }}</span> -->
          </template>
        </el-table-column>
    </el-table>
    <el-row type="flex" justify="end">
        <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
          :total="parseInt(totalPages)">
        </el-pagination>
    </el-row>
  </div>
</template>
<script>
// import { search, getPage } from '@/api/user'
import { listGroup, getPageList, send, destroy, exportExcel } from '@/api/messageGroup'

export default {
  data () {
    return {
      searchForm: {
        query: '',
        initDate: '',
        endDate: ''
      },
      totalPages: '',
      totalItemPage: '',
      currentPage: '',
      list: null,
      loading: false,
      loadingSend: false,
      loadingDelete: false
    }
  },
  mounted () {
    this.handleSearch()
  },
  methods: {
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPageList(page, this.showItempageValue)
        .then(response => {
          this.list = response.data.data
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      await getPageList(this.currentPage, val)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleSearch () {
      this.loading = true
      await listGroup(this.searchForm)
        .then(response => {
          console.log(response.data)
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCreated () {
      this.$router.push({ name: 'messages-group.create' })
    },
    handleSend (index, row) {
      this.loadingSend = true
      this.$confirm('Los mensajes serán preparados para ser enviados en la fecha especificada y ya no podrán ser eliminados, ¿desea continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await send(row._id)
            .then(response => {
              this.list = response.data.data
              this.totalPages = response.data.total
              this.$message({
                showClose: true,
                message: 'Se envió correctamente!',
                type: 'success'
              })
              // this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Envio cancelado'
          })
        })
        .finally(() => (this.loadingSend = false))
    },
    handleDestroy (index, row) {
      this.loadingDelete = true
      this.$confirm('Eliminar el grupo también elimina los mensajes relacionados. ¿Continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await destroy(row._id)
            .then(response => {
              // this.list = response.data.data
              // this.totalPages = response.data.total
              this.$message({
                showClose: true,
                message: 'Se eliminó correctamente!',
                type: 'success'
              })
              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          })
        })
        .finally(() => (this.loadingDelete = false))
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.status === 'Uninitialized' || row.status === 'Preview') {
        return 'info-row'
      }
      return ''
    },
    async exportExcelFile () {
      this.loading = true
      await exportExcel(this.searchForm)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]))
          const link = document.createElement('a')
          link.href = url
          const name = 'Reporte_de_grupo_mensajes_' + new Date() + '.xlsx'
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm.query = ''
      this.searchForm.initDate = ''
      this.searchForm.endDate = ''
      this.handleSearch()
    }
  }
}
</script>
